import { Component, OnInit, Inject, Renderer, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/platform-browser';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { AuthService } from './_core/services/auth.service';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'body[m-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;

    constructor( private renderer : Renderer, private router: Router, @Inject(DOCUMENT,) 
    private document: any, 
    private element : ElementRef, 
    public location: Location,
    private authService: AuthService,
    private metaTagService: Meta,
    private titleService: Title
    ) {}

    private intervalRefreshToken = null;

    ngOnInit() {
      // this.setTagsAndTitle();

      this.intervalRefreshToken = setInterval(() => {
        if (this.authService.isLoggedIn()) {
          this.authService.refreshToken().subscribe(() => { });
        }
      }, environment.timeToRefreshToken)
    }

  ngOnDestroy(): void {
		if (this.intervalRefreshToken != null) {
			this.intervalRefreshToken.clearInterval();
		}
  }
  
  setTagsAndTitle() {
    this.titleService.setTitle('CASA VERDE')
      
    //this.metaTagService.updateTag({ name: 'keywords', content: 'Angular SEO Integration, Music CRUD, Angular Universal' });
    this.metaTagService.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    this.metaTagService.updateTag({ name: 'description', content: 'Ingresa y descubre todas las promociones en alimentos frescos y en conserva. ¡Ingresa y haz tu pedido online!' });

    this.metaTagService.updateTag({ property: 'og:title', content: 'CASA VERDE' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    //this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.bembos.com.pe/bembos/images/logo_1200_x_630.png?v=2' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://casaverdeperu.com/' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Ingresa y descubre todas las promociones en alimentos frescos y en conserva. ¡Ingresa y haz tu pedido online!' });
  }

  onActivate(event) {
    window.scroll(0,0);
  }
}
