import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators'
import { HeaderBasicAuthorizationService } from '../../_shared/services/header-basic-authorization.service';
import { JwtService } from '../../_shared/services/jwt.service';
import { StorageService } from '../../_shared/services/storage.service';
import { environment } from '../../../environments/environment';
import { ApiEnum } from '../../_shared/enums/api.enum';
import { StorageKeyEnum } from '../../_shared/enums/storage-key.enum';
import { PermissionNavigationService } from '../../_shared/services/permission-navigation.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    numberDetails = 0;
    isLoggedSocial = false;
    centroSelected = 0;

    constructor(private router: Router,
        private storageService: StorageService,
        private jwtService: JwtService,
        private httpClient: HttpClient,
        private headerBasicAuthorization: HeaderBasicAuthorizationService,
        private permissionNavigationService: PermissionNavigationService) { }

    login(login: string, password: string) {
        return this.httpClient.post(`${environment.api.WS_GCH.url}/Colaborador/Login`, 
            {
                dni: login,
                password: password
            }, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_GCH)
            }
        )
            .pipe(
                tap((response: any) => {
                    if (response.Ok) {
                        this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                        this.jwtService.load(response.JWT);
                        this.storageService.set("DNI", response.Dni);
                        this.storageService.set("Nombres", response.Nombres);
                        this.storageService.set("Apellidos", response.Apellidos);
                    }
                })
            )
    }

    logoff() {
        this.permissionNavigationService.reset();
        this.storageService.remove(StorageKeyEnum.JWT_AUTHORIZATION);
        this.jwtService.clear();
    }

    refreshToken() {
        return this.httpClient.get(`${environment.api.WS_GCH.url}/Colaborador/RefreshToken`, {
            headers: this.headerBasicAuthorization.get(ApiEnum.WS_GCH)
        }).pipe(
            tap((response: any) => {
                if (response.Ok) {
                    this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                    this.jwtService.load(response.JWT);
                }
            })
        );
    }

    isLoggedIn() {
        let jwt: string = this.storageService.get(StorageKeyEnum.JWT_AUTHORIZATION);
        if (jwt != null) {
            this.jwtService.load(jwt);
            return this.jwtService.isValid();
        } else {
            return false;
        }
    }

    redirectToLogin() {
        this.router.navigate(['/Login']);
    }
    redirectToHome() {
        this.router.navigate(['/Home']);
    }

    redirectToMain() {
        this.router.navigate(['/']);
        //this.router.navigate(['/welcome']);
    }

    redirectToNotAllowed() {
        this.router.navigate(['/error/not-allowed']);
    }
}