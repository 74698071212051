export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  codigoSistema: '0001',
  timeToRefreshToken: 3000,
  channel: 'web',
  merchantId: '522591303',
  endPointJS: "https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true",
  siteKey: '6Le3d78ZAAAAANndqU27yzGL6PdEdY_ZQO4hAaO0',
  privateKey: 'pk_test_YXJ1p5A6CZ3yPRBC',
  apiKeyGOOGLE_MAPS: 'AIzaSyCZM8sPvTxqbzspI4Y4j2Joop7qjR63jE0',
  social: {
    FACEBOOK_ID: '313635033369228',
    GOOGLE_ID: '742815218794-7gf5c9eslqbvalftjipq1vu3097qtd34.apps.googleusercontent.com'
  },

  jwt: {
    audience: 'DefaultAudience',
    issuer: 'DefaultIssuer'
  },

  /*WEB SERVICES*/
  api: {
    WS_GCH: {
      url: 'https://app.danper.com:9018/WS_GCH/api',
      basicAuthorization: {
        username: 'DnpGCHApp',
        password: '975318642'
      }
    },
    WS_IT: {
      url: 'http://localhost:50241/api',
      basicAuthorization: {
        username: 'VnITApp',
        password: '975318642'
      }
    },
    WS_ECOMMERCE: {
      url: 'http://localhost:50073/api',
      basicAuthorization: {
        username: 'VnSDApp',
        password: '975318642'
      }
    },
    WS_TARIFARIO: {
      url: 'http://waetitaxi.azurewebsites.net/WS_TX/api/tarifa'
    },
    WS_VISANET: {
      url: 'http://localhost:60777/api',
      basicAuthorization: {
        username: 'VnVNApp',
        password: '975318642'
      }
    }
  },

  firebaseMyDanper: {
    apiKey: "AIzaSyB5-XJrlaTa0CIrTb8Krv8SpgZpT94HFlE",
    authDomain: "somos-danper-web.firebaseapp.com",
    databaseURL: "https://somos-danper-web.firebaseio.com",
    projectId: "somos-danper-web",
    storageBucket: "somos-danper-web.appspot.com",
    messagingSenderId: "1079916010069",
    appId: "1:1079916010069:web:8e74ded5ee14b98e2312dc",
    measurementId: "G-ZWDM68PRG4"
  },
};